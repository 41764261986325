/**
 * External dependencies:
 */
import $ from "jquery";
import route from "@/utils/route";

/**
 * Internal dependencies:
 */
import { createApi } from "@/frontend/domain/cart/local-storage-api";
import { cartPopupInstance } from "@/frontend/modules/cart-popup";

const storageApi = createApi();

/**
 * @param {JQuery<HTMLElement>} $button
 */
const changeToViewCartButton = ($button) => {
    $button.addClass("hidden");
    $button.parent().find(".js-view-cart").removeClass("hidden");
};

const changeToAddToCartButton = (event) => {
    const productId = event.target;
    const $button = $(`.js-view-cart[data-product-id="${productId}"]`);

    $button.addClass("hidden");
    $button.parent().find(".js-add-to-cart").removeClass("hidden");
};

/**
 * @param {JQuery.Event} event
 */
const onAddToCart = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const $button = $(event.currentTarget);

    const productId = parseInt($button.data("productId"));

    if (!productId) {
        return;
    }

    if ($button.data("must-login") === true) {
        $(".js-popup-login").trigger("click");
        return;
    }

    const res = await storageApi.addItem(productId);

    if (res.success) {
        changeToViewCartButton($button);
    } else {
        showToastMessage(5000, res?.errors?.at(0) ?? res?.error);
    }
};

/**
 * @param {JQuery.Event} event
 */
const onViewCart = (event) => {
    event.preventDefault();
    event.stopPropagation();

    location.href = route("cart.show");
};

$(".js-add-to-cart").each(function() {
    $(this).on("click", onAddToCart);
});

$(".js-view-cart").each(function() {
    $(this).on("click", onViewCart);
});

$("[data-add-with-quantity]").on("click", "a, button", async function() {
    const $parent = $(this).closest("[data-add-with-quantity]");
    const [quantityInput] = $parent.find("input").get();

    const productId = parseInt($parent.data("productId"));
    const quantity = parseInt(quantityInput?.value);

    if (!productId || !quantity || quantity < 1) {
        return;
    }

    const res = await storageApi.addMultipleItems(productId, quantity);

    if (res.success) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        cartPopupInstance.open();
    } else {
        showToastMessage(5000, res?.errors?.at(0) ?? res?.error);
    }
});

let toastVisible = false;
export const showToastMessage = (
    duration = 3000,
    message = "Item added to cart.",
) => {
    if (toastVisible) {
        return;
    }

    const toast = document.querySelector(".cart-toast");
    toast.textContent = message;
    toast.classList.add("show");
    toastVisible = true;

    setTimeout(() => {
        toast.classList.remove("show");
        toastVisible = false;
    }, duration);
};

const $cartIcon = $(".icon-counter");

const updateCartCounter = async () => {
    const items = await storageApi.getItems();
    const count =
        items?.reduce((acc, product) => acc + parseInt(product.count), 0) || 0;
    $cartIcon.attr("data-counter", count);
};

let countdownInterval = null;

const updateCartTimer = async () => {
    const time = await storageApi.getCartTimer();
    const $timer = $(".cart-timer");

    if (countdownInterval) {
        clearInterval(countdownInterval);
    }

    if (time) {
        $timer.show();

        countdownInterval = setInterval(() => {
            updateCountdown(time);
        }, 1000);

        updateCountdown(time);
    } else {
        clearInterval(countdownInterval);
        $timer.hide();
    }
};

storageApi.on("add", updateCartCounter);
storageApi.on("add", updateCartTimer);
storageApi.on("removed", updateCartCounter);
storageApi.on("removed", changeToAddToCartButton);
storageApi.on("removed", updateCartTimer);
storageApi.on("set-count", updateCartCounter);
storageApi.on("set-count", updateCartTimer);

const updateCountdown = (time) => {
    const now = Date.now();
    const expireTime = time * 1000;
    const timeRemaining = expireTime - now;
    const $timer = $(".cart-timer");

    if (timeRemaining > 0) {
        const hours = padZero(
            Math.floor((timeRemaining / (1000 * 60 * 60)) % 24),
        );
        const minutes = padZero(Math.floor((timeRemaining / (1000 * 60)) % 60));
        const seconds = padZero(Math.floor((timeRemaining / 1000) % 60));

        $timer.text(`${hours}:${minutes}:${seconds}`);
    } else {
        $timer.text(`00:00:00`);
    }
};

function padZero(num) {
    return num.toString().padStart(2, "0");
}

updateCartTimer();
updateCartCounter();
